import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import(/* webpackChunkName: "login_home_welcome" */ '../components/Home.vue')
const Login = () => import(/* webpackChunkName: "login_home_welcome" */ '../components/Login.vue')
const Welcome = () => import(/* webpackChunkName: "login_home_welcome" */ '../components/Welcome.vue')

const Admin = () => import(/* webpackChunkName: "user" */ '../components/user/Admin.vue')
const Roles = () => import(/* webpackChunkName: "user" */ '../components/user/Roles.vue')

const Category = () => import(/* webpackChunkName: "category" */ '../components/product/Category.vue')
const Field = () => import(/* webpackChunkName: "category" */ '../components/product/Field.vue')

const ProductList = () => import(/* webpackChunkName: "Product" */ '../components/product/Product.vue')
const ProductForm = () => import(/* webpackChunkName: "Product" */ '../components/product/ProductForm.vue')

const Market = () => import(/* webpackChunkName: "Market" */ '../components/product/Market.vue')
const MarketForm = () => import(/* webpackChunkName: "Market" */ '../components/product/MarketForm.vue')

const Project = () => import(/* webpackChunkName: "Project" */ '../components/case/Project.vue')
const ProjectForm = () => import(/* webpackChunkName: "Project" */ '../components/case/ProjectForm.vue')

const Class = () => import(/* webpackChunkName: "news" */ '../components/company/Class.vue')
const EditClass = () => import(/* webpackChunkName: "news" */ '../components/company/EditClass.vue')
const News = () => import(/* webpackChunkName: "news" */ '../components/company/News.vue')
const NewsForm = () => import(/* webpackChunkName: "news" */ '../components/company/NewsForm.vue')

const Maptitle = () => import(/* webpackChunkName: "setting" */ '../components/swiper/Map.vue')
const Setting = () => import(/* webpackChunkName: "setting" */ '../components/swiper/Setting.vue')
const Swiper = () => import(/* webpackChunkName: "setting" */ '../components/swiper/Swiper.vue')
const Message = () => import(/* webpackChunkName: "setting" */ '../components/swiper/Message.vue')

const Article = () => import(/* webpackChunkName: "service" */ '../components/service/Article.vue')
const ArticleForm = () => import(/* webpackChunkName: "service" */ '../components/service/ArticleForm.vue')
const Download = () => import(/* webpackChunkName: "service" */ '../components/service/Download.vue')
const Video = () => import(/* webpackChunkName: "service" */ '../components/service/Video.vue')

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: './login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/admin', component: Admin },
      { path: '/roles', component: Roles },
      { path: '/category', component: Category },
      { path: '/product', component: ProductList },
      { path: '/product/add', component: ProductForm },
      { path: '/product/edit/:id', component: ProductForm },
      { path: '/market', component: Market },
      { path: '/market/add', component: MarketForm },
      { path: '/market/edit/:id', component: MarketForm },
      { path: '/field', component: Field },
      { path: '/project', component: Project },
      { path: '/project/add', component: ProjectForm },
      { path: '/project/edit/:id', component: ProjectForm },
      { path: '/class', component: Class },
      { path: '/class/edit/:id', component: EditClass },
      { path: '/news', component: News },
      { path: '/news/add', component: NewsForm },
      { path: '/news/edit/:id', component: NewsForm },
      { path: '/map', component: Maptitle },
      { path: '/setting', component: Setting },
      { path: '/swiper', component: Swiper },
      { path: '/message', component: Message },
      { path: '/article', component: Article },
      { path: '/article/add', component: ArticleForm },
      { path: '/article/edit/:id', component: ArticleForm },
      { path: '/download', component: Download },
      { path: '/video', component: Video }

    ]
  }

]

const router = new VueRouter({
  routes
})

// 路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})

export default router
